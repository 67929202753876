<template>
  <div class="login-div">
    <div id="Login">
      <Form ref="formInline" :model="formInline" :rules="ruleInline" :lable-width="80">
        <div id="captcha-div"></div>

        <FormItem>
          <div :style="{color:Color}" class="logintitle">欢迎加入coffeeo小家庭!</div>
        </FormItem>
        <!--用户名-->
        <FormItem prop="userName">
          <Input type="text" v-model="formInline.userName" placeholder="请输入用户名">
            <Icon type="ios-person-outline" slot="prepend"></Icon>
          </Input>
        </FormItem>
        <!--密码-->
        <FormItem prop="password">
          <Input type="password" v-model="formInline.password" placeholder="请输入密码">
            <Icon type="ios-lock-outline" slot="prepend"></Icon>
            <img src="iconfont.svg"/>
          </Input>
        </FormItem>
        <!--邮箱-->
        <FormItem v-if="isShow" prop="email">
          <Input placeholder="Email" v-model="formInline.email">
            <Icon type="ios-mail-outline" slot="prepend"/>
            <!--          <span slot="append">@qq.com</span>-->
          </Input>
        </FormItem>
        <!-- 发送验证码 -->
        <Button
            v-show="isShow"
            :disabled="showBtn"
            @click.native="sendMail"
            class="btns"
            style="margin:0.5rem 0;"
            type="info">发送验证码
        </Button>
        <!--验证码-->
        <FormItem v-if="isShow" prop="code">
          <Input class="put_code" v-model="formInline.code" placeholder="输入验证码"></Input>
        </FormItem>
        <FormItem>
          <Button v-show="isShow" class="btns" type="warning" @click="formSubmit('formInline')">注册</Button>
          <Button v-show="!isShow" class="btns" type="success" @click="formSubmit('formInline')">登陆</Button>
          <Button v-show="isShow" class="btns" style="margin-left:1rem;" type="warning" @click="register">返回</Button>
          <Button v-show="!isShow" class="btns" style="margin-left:1rem;" type="warning" @click="register">我是新人
          </Button>
        </FormItem>
        <FormItem v-show="!isShow">
          <p :style="{color:Color}" style="font-size:1rem;">提示:
            没有账号可以点击我是新人进行注册,只需要验证邮箱就可以了,欢迎你的加入！</p>
        </FormItem>
        <FormItem v-show="isShow">
          <p :style="{color:Color}" style="font-size:1rem;">提示: 只支持qq,gmail,163！</p>
        </FormItem>
        <div class="social-login">
          <span v-if="isShow">您可以选择以下几种方式注册您的账户!</span>
          <span v-if="!isShow">您可以选择以下几种方式登陆您的账户!</span>
          <div class="social-container">
            <a href="#" class="social" @click="socialLoginOrBind('GITEE')">
              <i class="iconfont icon-GITEE"></i>
            </a>
            <a href="#" class="social" @click="socialLoginOrBind('github')">
              <i class="iconfont icon-github1"></i>
            </a>
<!--            <a href="#" class="social" @click="socialLoginOrBind('QQ')">-->
<!--              <i class="iconfont icon-QQ"></i>-->
<!--            </a>-->
<!--            <a href="#" class="social" @click="socialLoginOrBind('WeXin')">-->
<!--              <i class="iconfont icon-weixin"></i>-->
<!--            </a>-->
<!--            <a href="#" class="social" @click="socialLoginOrBind('WeiBo')">-->
<!--              <i class="iconfont icon-weibo"></i>-->
<!--            </a>-->
            <a href="#" class="social" @click="socialLoginOrBind('DINGTALK')">
              <i class="iconfont icon-dingding"></i>
            </a>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import {getSocialLoginUrl, PostMessage} from "../components/NetWork/request";
import "@/assets/css/captcha/tac.css" // 验证码css
import "@/assets/js/jquery.min.js"; // 验证码js
import "@/assets/js/captcha/tac.js"; // 验证码js

export default {
  data() {
    //验证邮箱的规则
    const checkEmail = (rule, value, cb) => {
      //验证邮箱的正则表达式
      //const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      const regEmail = /^[\w._]+@(qq|gmail|163)\.com(\r\n|\r|\n)?$/
      if (regEmail.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    };
    const checkUserName = (rule, value, cb) => {
      const regUserName = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi
      if (!regUserName.test(value)) {
        return cb()
      }
      cb(new Error('登录账号不得包含中文'))
    }
    return {
      isShow: false,
      showBtn: false,
      timer: null,
      count: 60,
      btntext: "发送验证码",
      formInline: {
        userName: "",
        password: "",
        email: "",
        code: ""
      },
      ruleInline: {
        userName: [
          {
            required: true,
            message: "快快输入用户名,时间不等人呐!",
            trigger: "blur"
          },
          {
            type: "string",
            max: 11,
            min: 3,
            message: "用户长度最少为5位,最多11位哦,（＞人＜；）",
            trigger: "blur"
          },
          {validator: checkUserName, trigger: 'blur'}
        ],
        password: [
          {required: true, message: "还有密码密码密码!", trigger: "blur"},
          {
            type: "string",
            min: 3,
            max: 16,
            message: "密码长度最少为8位,最多16位哦,（＞人＜；）",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "这里填你的qq号鸭,我会给你发邮箱验证的",
            trigger: "blur"
          },
          {
            validator: checkEmail, trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: "你还没有输入验证码,不要着急！",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    Color() {
      return this.$store.state.Color;
    }
  },
  mounted() {
    if (localStorage.getItem("dlsjalkjdkljaslfjldjgltlfgdoeiroeiotgjfkdjk")) {
      this.showBtn = true;
      this.count = parseInt(
          localStorage.getItem("dlsjalkjdkljaslfjldjgltlfgdoeiroeiotgjfkdjk")
      );
      this.timer = setInterval(() => {
        this.count -= 1;
        if (this.count === 0) {
          clearInterval(this.timer);
          localStorage.removeItem(
              "dlsjalkjdkljaslfjldjgltlfgdoeiroeiotgjfkdjk"
          );
          this.btntext = "发送验证码";
          this.showBtn = false;
        } else {
          this.btntext = `${this.count}秒后重新发送`;
          localStorage.setItem(
              "dlsjalkjdkljaslfjldjgltlfgdoeiroeiotgjfkdjk",
              this.count
          );
        }
      }, 1000);
    } else {
      clearInterval(this.timer);
    }
    /* 键盘事件 */
    document.onkeydown = e => {
      var key = e["keyCode"];
      if (key === 13) {
        this.handleSubmit("formInline");
      }
    };
  },
  methods: {
    formSubmit(name) {
      // // 样式配置
      const config = {
        requestCaptchaDataUrl: "auth-api/security/captcha/gen",
        validCaptchaUrl: "auth-api/security/captcha/check",
        bindEl: "#captcha-div",
        // 验证成功回调函数
        validSuccess:(res,c,tac)=> {
          this.handleSubmit(name)
          tac.destroyWindow();
        }
      }
      new window.TAC(config).init();
    },
    socialLoginOrBind(socialType) {
      getSocialLoginUrl(socialType).then(res => {
        if (res.data.code === 200) {
          //获取到后端传递过来的授权路径
          console.log('>>>>=data', res.data.result);
          //跳转到gitee授权页
          console.log('跳转到gitee授权页',res.data.result)
          // childWindow.postMessage(JSON.stringify(dataToSend), '*');
          window.location.href = res.data.result;
        } else {
          this.$Message.error(res.data.message);
        }
      })
    },

    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          /* 登陆失败走注册模式 */
          if (this.isShow) {
            this.$store.commit("LoadingTitleChange", {
              isShow: true,
              title: "正在注册您的账号请稍等..."
            });
            PostMessage("/auth-api/security/register", this.formInline).then(res => {
              if (res.data.code === 200) {
                // this.$Message.success(
                //   "注册成功啦小主,3秒后为您跳转到首页(❤ ω ❤)!"
                // );
                /* 保存一下token */
                localStorage.setItem("token", res.data.result.token);
                // clearInterval(this.timer);
                // localStorage.removeItem(
                //   "dlsjalkjdkljaslfjldjgltlfgdoeiroeiotgjfkdjk"
                // );
                // setTimeout(() => {
                //   this.$router.back();
                // }, 1000);
                this.$router.push('/home')
                this.$Message.success("注册成功啦小主,3秒后为您跳转到首页(❤ ω ❤)!");
                setTimeout(() => {
                  location.reload()
                }, 1000);
              } else {
                this.$Message.warning(res.data.message);
              }
              this.$store.commit("LoadingTitleChange", {
                isShow: false,
                title: ""
              });
            });
          } else {
            /* 默认走登陆模式 */
            this.$store.commit("LoadingTitleChange", {
              isShow: true,
              title: "正在登陆请稍等片刻..."
            });
            PostMessage("/auth-api/security/login", this.formInline).then(res => {
              if (res.data.code === 200) {
                localStorage.setItem("token", res.data.result.token);
                this.$Message.success("登陆成功啦,马上带您去游览我的小站!");
                // setTimeout(() => {
                //   this.$router.push('/profile')
                //   location.reload()
                // }, 1000)
                setTimeout(() => {
                  this.$router.back();
                }, 1000);
              } else if (res.data.err == -998) {
                /* 报错查不到用户 那么我们就要进行一个注册 */
                this.$Message.error(res.data.message);
              } else {
                this.$Message.error(res.data.message);
              }
              this.$store.commit("LoadingTitleChange", {
                isShow: false,
                title: ""
              });
            }).catch(reason => {
              this.$Message.error(reason.message);
            });
          }
        } else {
          this.$Message.error("请输入完整信息,o(*￣▽￣*)o!");
        }
      });
    },
    register() {
      this.isShow = !this.isShow;
    },
    sendMail() {
      /* 发送邮箱验证码 */
      if (this.formInline.email !== "") {
        /* 验证邮箱是正确 */
        //const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
        const regEmail = /^[\w._]+@(qq|gmail|163)\.com(\r\n|\r|\n)?$/
        if (regEmail.test(this.formInline.email) === true) {
          /* 发起验证码请求 */
          PostMessage("/web-api/mail", {
            email: this.formInline.email
          }).then(res => {
            if (res.data.code === 200) {
              /* 发送成功 */
              this.$Message.success(res.data.message);
              /* 进行倒计时 设置定时一分钟后可访问 */
              this.showBtn = true;
              this.count = 60;
              this.timer = setInterval(() => {
                this.count -= 1;
                if (this.count === 0) {
                  clearInterval(this.timer);
                  this.btntext = "发送验证码";
                  this.showBtn = false;
                } else {
                  this.btntext = `${this.count}秒后重新发送`;
                }
              }, 1000);
            } else {
              this.$Message.error(res.data.message);
            }
          });
        } else {
          this.$Message.error("输入正确的邮箱噢，太马虎啦！");
        }
      } else {
        this.$Message.error("邮箱不能空着呢！");
      }
      // this.$refs[name].validate(valid => {
      //   if (valid) {
      //     console.log(true)
      //   } else {
      //     console.log(false)
      //   }
      // })
    }
  }
};
</script>
<style lang="scss" scoped>
#Login {
  width: 90%;
  height: 80vh;
  margin: 0 auto;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .btns,
  .put_code {
    position: relative;
    z-index: 5;
  }

  .btns:nth-child(1) {
    background: rgb(53, 138, 235);
    color: white;
    border: 0;
  }

  .logintitle {
    font-size: 2rem;
  }
}

#captcha-div {
  width: 200px; /* 设置 div 的宽度 */
  height: 200px; /* 设置 div 的高度 */
  margin: 0 auto; /* 设置左右 margin 为 auto，使 div 在水平方向上居中 */
}

.social-container {
  margin: 0.6rem 0;

  i {
    padding: 0.35rem 0.6rem;
    border-radius: 50%;
    font-size: 1.4rem;
    margin: 0 1rem;
    cursor: pointer;
    transition: all 0.6s;
  }

  i.icon-weibo {
    border: 1px solid rgb(255, 153, 0);
    color: rgb(255, 153, 0);
  }

  i.icon-weibo:hover {
    background: rgb(255, 153, 0);
    color: white;
  }

  i.icon-weixin {
    border: 1px solid lightgreen;
    color: lightgreen;
  }

  i.icon-weixin:hover {
    background: lightgreen;
    color: white;
  }

  i.icon-qq {
    border: 1px solid skyblue;
    color: skyblue;
  }

  i.icon-qq:hover {
    background: skyblue;
    color: white;
  }

  i.icon-GITEE {
    border: 1px solid #ff1323;
    color: #ff1323;
  }

  i.icon-GITEE:hover {
    background: #ff1323;
    color: white;
  }

  i.icon-github1 {
    border: 1px solid #908787;
    color: #a29798;
  }

  i.icon-github1:hover {
    background: #a29798;
    color: white;
  }
  i.icon-dingding {
    border: 1px solid #15b4e5;
    color: #15b4e5;
  }

  i.icon-dingding:hover {
    background: #15b4e5;
    color: white;
  }

  i.icon-qzone {
    border: 1px solid yellow;
    color: yellow;
  }

  i.icon-qzone:hover {
    background: yellow;
    color: white;
  }
}

.social-container a {
  border: 1px solid #eee;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 2.8rem;
  width: 2.8rem;
}

.social-container a:hover {
  opacity: 0.8;
}
</style>